<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("subGroups.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="createSubGroup()" v-if="showForAdmin()">{{ $t("subGroups.new") }}</b-button>
          </template>
          <template v-slot:body>
            <div class="info">
              <table class="info-table">
                <tr>
                  <td class="label">{{ $t("subGroups.group") }}</td>
                  <td>{{ subjectInfo.groupName }}</td>
                </tr>
                <tr>
                  <td class="label">{{ $t("subGroups.subject") }}</td>
                  <td>{{ subjectInfo.name }}</td>
                </tr>
                <tr>
                  <td class="label">{{ $t("subGroups.teacher") }}</td>
                  <td>{{ subjectInfo.teacherName }}</td>
                </tr>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-form-group>
                  <template v-slot:label>

                  </template>
                  <b-form-checkbox
                      v-model="showArchive"
                      @change="filterStudents"
                  >
                    {{ $t("common.showDroppedStudents") }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="row"> 
              <div class="col-lg-12 col-xl-12">
                <h3 class="font-size-h6 mb-5">{{ $t("subGroups.new") }}</h3>
                <div class="separator separator-dashed "></div>
              </div>
            </div>
            <div class="row" v-if="showAddAnotherError">
              <div class="col-lg-12">
                <div class="alert alert-danger">
                  Должно быть больше одной подгруппы
                </div>
              </div>
            </div>



            <div class="row" :hasErrors="hasErrors">
              <div v-bind:class="subgroupClass" v-for="(subgroup, k) in subGroups" :key="k">
                <div style="margin: 10px; border: 1px dashed #000000; padding: 10px">
                  <div style="margin-bottom: 10px; text-align: right">
                    <button class="btn btn-danger" @click="deleteSubGroup(k)">{{ $t("common.delete") }}</button>
                  </div>
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("subGroups.name") }}
                    </template>
<!--                    <b-form-input
                        id="input-1"
                        v-model="subgroup.subjectName"
                        :state="subgroup.states.subjectName"
                        required
                    ></b-form-input>-->
                    <b-form-select
                        v-model="subgroup.subjectName"
                        :options="subgroupNames"
                        :state="subgroup.states.subjectName"
                        required
                    ></b-form-select>
                    <div class="invalid-feedback">Заполните название</div>
                  </b-form-group>
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("subGroups.teacher") }}
                    </template>
                    <b-form-select
                        v-model="subgroup.teacherId"
                        :options="teachers"
                        :state="subgroup.states.teacherId"
                        required
                    ></b-form-select>
                    <div class="invalid-feedback">Выберите учителя</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.classWorkPerWeekHours") }}
                    </template>
                    <b-form-select
                        v-model="subgroup.classWorkPerWeekHours"
                        :options="classWorkPerWeekHoursList"
                        :state="subgroup.states.classWorkPerWeekHours"
                    ></b-form-select>
                    <div class="invalid-feedback">Обязательное поле</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.roomNumber") }}
                    </template>
                    <b-form-select
                        v-model="subgroup.classroomId"
                        :options="classrooms"
                        :state="subgroup.states.classroomId"
                    ></b-form-select>
                    <div class="invalid-feedback">Обязательное поле</div>
                  </b-form-group>
                  <div>
                    <v-simple-table fixed-header>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("subGroups.studentFullName") }}
                          </th>
                          <th class="text-left"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="student in studentsFiltered" :key="student.key" :class="isDropped(student)">
                          <td v-bind:class="{ss : student.subGroup}">{{ student.firstName }} {{ student.name }}
                            {{ student.patronymic }}
                          </td>
                          <td>
                            <label class="checkbox">
                              <input type="checkbox" v-model="subgroup.students[student.id]"
                                     @change="pickStudent(student.id, subgroup)">
                              <span></span>
                            </label>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b-button type="submit" @click="onSubmit" variant="primary" 
                            id="submitButton"
                            :class="{'kt-spinner kt-spinner--light disabled':loading }"
                            :disabled="loading"
                  >
                    {{ $t("common.save") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.separator.separator-dashed {
  border-bottom: 1px dashed #EBEDF3;
}

.separator {
  height: 0;
}

.info-table td{
  padding: 5px 10px;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import {mapGetters} from "vuex";

export default {
  name: "groupSubjectsSubGroup",
  components: {
    KTPortlet
  },
  data() {
    return {
      showAddAnotherError: false,
      groupSubjectId: null,
      subGroups: [],
      subjectInfo: {},
      subgroupClass: 'col-lg-12',
      teachers: [],
      students: [],
      hasErrors: false,
      toDelete: [],
      loading: true,
      subgroupNames: [{text: "", value: ""}, {text: "гр1", value: "гр1"}, {text: "гр2", value: "гр2"}, 
        {text: "гр3", value: "гр3"}, {text: "гр4", value: "гр4"}],
      classWorkPerWeekHoursList:[],
      classrooms: [],
      showArchive: false,
      studentsFiltered: [],
    };
  },
  mounted() {
    this.classWorkPerWeekHoursList = DictionariesService.getClassWorkPerWeekHoursList();
    let $this = this;
    
    ApiService.querySecured("dictionaries/getClassrooms", {}).then(({data}) => {
      $this.classrooms = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.showAddAnotherError = false;
      this.loading = true;
      
      if(this.subGroups.length < 2){
        this.showAddAnotherError = true;
        
        return;
      }
      
      
      let subjectId = this.$route.params.id;

      let data = [];
      
      let hasErrors = false;
      for (let i in this.subGroups) {
        let subgroup = this.subGroups[i];
        
        subgroup.states['subjectName'] = null;        
        subgroup.states['teacherId'] = null;
        subgroup.states['classWorkPerWeekHours'] = null;
        subgroup.states['roomNumber'] = null;
        subgroup.states['c'] = null;
        
        if(subgroup.subjectName.trim() === ""){
          subgroup.states['subjectName'] = false;

          hasErrors = true;
        }

        if((isNaN(subgroup.teacherId) || subgroup.teacherId === "") || subgroup.teacherId === 0){
          subgroup.states['teacherId'] = false;

          hasErrors = true;
        }    
        
        if((isNaN(subgroup.classWorkPerWeekHours) || subgroup.classWorkPerWeekHours === "") || subgroup.classWorkPerWeekHours === 0){
          subgroup.states['classWorkPerWeekHours'] = false;

          hasErrors = true;
        } 
        
        if(!(parseInt(subgroup.classroomId) > 0)){
          subgroup.states['classroomId'] = false;

          hasErrors = true;
        }
        
        let subgroupD = {};

        subgroupD.id = subgroup.id;
        subgroupD.subjectName = subgroup.subjectName;
        subgroupD.teacherId = subgroup.teacherId;
        subgroupD.groupSubjectId = subjectId;
        subgroupD.classWorkPerWeekHours = subgroup.classWorkPerWeekHours;
        subgroupD.classroomId = subgroup.classroomId;

        let students = [];
        for (let studentId in subgroup['students']) {
          if (subgroup['students'][studentId] === true) {
            students.push(studentId);
          }
        }
        subgroupD['studentsIds'] = students;

        data.push(subgroupD);
      }

      if(hasErrors === true){
        
        this.hasErrors = hasErrors;
        this.loading = false;
        return;
      }

      let $this = this;
      ApiService.postSecured("groupSubjects/saveSubGroup", 
          {items:data, toDelete: this.toDelete})
          .then(function () {
            alert("Данные сохранены");
            $this.subGroups = [];
            $this.reloadData();
            
            $this.loading = false;
          })
          .catch(({response}) => {
            console.log(response);

            $this.loading = false;
          });
    },
    reloadData() {

      let $this = this;
      ApiService.querySecured("students/list", {
        params: {groupId: this.$route.params.groupId}
      }).then(({data}) => {
        this.students = [];
        for (let i in data.list) {
          let item = data.list[i];
          this.students.push({
            id: item.id,
            firstName: item.firstName,
            name: item.name,
            patronymic: item.patronymic,
            subGroup: "",
            isDropped: item.isDropped
          });
          
          
        }
        
        $this.filterStudents();        
        $this.loadSubGroups();
        
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/teachersByGroup", {
        params: {groupId: this.$route.params.groupId}
      })
          .then(({data}) => {
            this.teachers = data.dictionary;
          })
          .catch(({response}) => {
            console.log(response);
          });

      ApiService.querySecured("groupSubjects/find", {
        params: {id: this.$route.params.id}
      }).then(({data}) => {
        this.subjectInfo = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    loadSubGroups(){
      let $this = this;
      ApiService.querySecured("groupSubjects/getSubGroups", {
        params: {groupSubjectId: this.$route.params.id}
      }).then(({data}) => {
        if(data.length > 0){
          for (let i in data) {
            let item = data[i];

            let subGroups = {
              id: item.id,
              subjectName: item.subjectName,
              teacherId: item.teacherId,
              groupSubjectId: item.groupSubjectId,
              students: {},
              states: {},
              classWorkPerWeekHours: item.classWorkPerWeekHours,
              roomNumber: item.roomNumber,
              classroomId: item.classroomId,
            };

            for (let ii in item.students){
              let ss = item.students[ii];

              subGroups['students'][ss.studentId] = true;
            }

            $this.subGroups.push(subGroups);
            $this.subGroupColumnClass();

          }
        }else{
          $this.createSubGroup($this.subgroupNames[1].value);
          $this.createSubGroup($this.subgroupNames[2].value);
        }

        $this.loading = false;
      }).catch(({response}) => {
        console.log(response);

        $this.loading = false;
      });
    },
    cleanModel() {
      this.form = {};
    },
    subgroupsCount() {
      return this.subGroups.length;
    },
    subGroupColumnClass() {
      let length = this.subgroupsCount();
      let ret = 12;
      if (length > 0) {

        ret = Math.floor(12 / length);
      }

      this.subgroupClass = "col-lg-" + ret;
    },
    pickStudent(studentId, subgroup) {
      for (let i in this.subGroups) {
        if (subgroup === this.subGroups[i]) {
          continue;
        } else {
          if (typeof this.subGroups[i]['students'][studentId] != 'undefined') {
            this.subGroups[i]['students'][studentId] = false;
          }
        }
      }

      for (let ii in this.students) {
        if (this.students[ii].id == studentId) {
          this.students[ii].subGroup = subgroup.type;
        }
      }
    },
    createSubGroup(subgroupName) {
      let subGroup = {
        id: 0,
        subjectName: subgroupName,
        teacherId: 0,
        groupSubjectId: 0,
        students: {},
        states: {},
        classWorkPerWeekHours: 1,
        roomNumber: "",
        classroomId: 0,
      };
      
      if(this.subGroups.length === 0){
        for (let ii in this.students) {
          subGroup['students'][this.students[ii].id] = true;
        }
      }
      
      this.subGroups.push(subGroup);
      
      this.subGroupColumnClass();
    },
    deleteSubGroup(k) {
      
      if(this.subGroups.length > 1){
        let subgroup = this.subGroups[k];
        
        if(subgroup.id >0){
            this.toDelete.push(subgroup.id);
        }
        
        for (let i in subgroup['students']) {
          if(subgroup['students'][i] === true){
            this.subGroups[0]['students'][i] = true;
          }
        }
      }
      
      this.subGroups.splice(k, 1);
      this.subGroupColumnClass();
    },
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
    filterStudents(){
      this.studentsFiltered = this.students
          .filter((student)=> this.showArchive === true || student.isDropped === false);
    },
    isDropped(student) {
      return student.isDropped === true ? "table-danger" : "";
    },
  }
};
</script>
